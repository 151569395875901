import React from "react";
import { NotFound } from "@components";
import Layout from "@layouts";

const NotFoundPage = () => (
  <Layout seoTitle="404: Not found" showNav={false}>
    <NotFound />
  </Layout>
);

export default NotFoundPage;
